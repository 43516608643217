import axios from "axios";
import { Toast } from 'vant'
let protocol = document.location.protocol + "//";
let ajxUrl=protocol+"testex.makebuluo.cn/api/testex/";
export default function request(config) {
    // if(location.origin.indexOf("ihgclub")>-1){
    //     ajxUrl=protocol+"testex.makebuluo.cn/api/testex/";
    // }
    const instance = axios.create({
        baseURL: process.env.NODE_ENV == "development"?'/api/testex/':ajxUrl,
        timeout: 30000,
        method: config.method || 'get',
        // headers: {
        //     utk: localStorage.utk||'',
        //   },
    })
    //请求拦截
    instance.interceptors.request.use(config => {
        return config;
    }, error => {
        console.log(error)
    })
    //响应拦截
    instance.interceptors.response.use(res => {
        if (res.status === 500) {
            //对于其他的500错误，均包装为网络请求错误，并附带上相应的错误信息进行抛出
            throw new Error(`网络请求错误:${res.msg}`);
        }
        // if (res.data.state != 0) {
        //     Toast(res.data.msg)
        // }
        return res.data
    }), err => {
        Toast.clear()
        console.log(err)
    }
    //发送真正的网络请求
    return instance(config)
}