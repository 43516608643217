<template>
  <div class="color">
    <div class="cancel" @click="close"><img src="../assets/pege8/xxx.png" alt=""></div>
    <div class="Relative">
      <!-- 签到成功 -->
      <div class="success"><img src="../assets/pege8/baise.png" alt="">
        <div class="exactness">
          <img v-if="cfg.state==1" src="../assets/pege8/qiandaoshibai8.png" alt="">
          <img  v-else src="../assets/pege8/gou.png" alt="">
        </div>
        <div class="characters" :style="{color:cfg.state==1?'red':''}">
          {{cfg.msg}} 
        </div> 
        <div class="green">
          <img src="../assets/pege8/lvtiao.png" alt="">
          <div class="text" @click="close">确认</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    cfg:{
      type:Object,
      default:{},
    }
  },
  created(){
    console.log(this.cfg)
  },
  methods: {
    close(){
      this.$emit("close",this.cfg)
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';

.color {
  position: fixed;
  top: vw(0);
  left: vw(0);
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
}

.Relative {
  position: fixed;
  top: vw(438);
  left: vw(165);
}

.cancel {
  position: fixed;
  top: vw(400);
  right: vw(174);
  width: vw(18);
}

.success,
.defeated {
  position: relative;
  width: vw(417);
}

.success,
.defeated {
  position: relative;
  width: vw(417);
}

.exactness {
  position: absolute;
  top: vw(60);
  left: vw(158);
  width: vw(104);
}

.characters {
  position: absolute;
  top: vw(218);
  left:0;
  width: 100%;
  text-align: center;
  padding: 0 vw(20);
  font-size: vw(30);
  color: #00a687;
}

.green {
  position: absolute;
  top: vw(333);
  left: vw(118);
  width: vw(183);
}

.text {
  position: absolute;
  top: vw(8);
  left: vw(65);
  font-size: vw(23);
  letter-spacing: vw(3);
  color: #ffffff;
}</style>