<template>
  <div class="Wrap">
    <div class="Main">
      <!-- 公司名称，填表人姓名，电话 -->
      <div class="Bg"><img src="../assets/pege6/beijing6.jpg" alt=""></div>


      <div class="LeftLogo bottomTopAnimate"><img src="../assets/pege7/logo7.png" alt=""></div>
      <div class="RightLogo bottomTopAnimate"><img src="../assets/pege7/logo77.png" alt=""></div>
      <div class="return bottomTopAnimate" style="animation-delay: 200ms;" @click="$router.replace('SelectSignType')"><img src="../assets/pege7/fanhui7.png" alt=""></div>

      <div class="Relative bottomTopAnimate" style="animation-delay: 300ms;">
        <div class="frame"><img src="../assets/pege7/kuang7.png" alt="">
          <div class="corporateName">
            <img src="../assets/pege7/gongsimingcheng7.png" alt="">
            <input v-model="corporate" @focus="inputFocus(0)" @blur="inputIndex = ''" class="inputList" type="text">
          </div>
          <div class="fillIn" style="pointer-events: none;" v-if="!corporate && inputIndex !== 0"><img
              src="../assets/pege7/wenzihebi7.png" alt=""></div>
          <div class="name">
            <img src="../assets/pege7/tianbiaorenxingming7.png" alt="">
            <input v-model="inputName" @focus="inputFocus(1)" @blur="inputIndex = ''" class="inputList" type="text">
          </div>
          <div class="fillIn1" style="pointer-events: none;" v-if="!inputName && inputIndex !== 1"><img
              src="../assets/pege7/wenzihebi7.png" alt=""></div>
          <div class="telephone">
            <img src="../assets/pege7/dianhua7.png" alt="">
            <input v-model="phone" @focus="inputFocus(2)" @blur="inputIndex = ''" class="inputList" type="tel"
              maxlength="11">
          </div>
          <div class="fillIn2" style="pointer-events: none;" v-if="!phone && inputIndex !== 2"><img
              src="../assets/pege7/wenzihebi7.png" alt=""></div>
          <div class="green" @click="submitInfo"><img src="../assets/pege7/lvtiao7.png" alt=""></div>
          <div class="PunchTheClock" style="pointer-events: none;">点击签到</div>
          <div class="text"><img src="../assets/pege7/wenzi7.png" alt=""></div>
        </div>
      </div>
 
      <tips :cfg="tipsConfig" v-if="showTips" @close="onTipsClose"></tips>

    </div>

  </div>

</template>
<script>
import request from "@/util/request";
import { Toast, Dialog } from 'vant';
import tips from '../components/tips.vue';
// @ is an alias to /src 
export default {
  name: "inputInfo",
  data() {
    return {
      corporate: "",
      inputName: "",
      phone: "",
      inputIndex: '',
      showTips:false,
      tipsConfig:{},
    };
  },

  components:{tips},

  methods: {

    inputFocus(index) {

      this.inputIndex = index;

    },

    submitInfo() {

      if(!this.corporate){
        this.tipsConfig={state:1,msg:'请填写公司名称'};
        this.showTips=true;
        return
      }

      if(!this.corporate){
        this.tipsConfig={state:1,msg:'请填写姓名'};
        this.showTips=true;
        return
      }
      if(!this.corporate){
        this.tipsConfig={state:1,msg:'请填写电话'};
        this.showTips=true;
        return
      }
      if(!localStorage.uuid){
        this.tipsConfig = {state:1,msg:"用户信息错误"};
        this.showTips = true;
        return
      }
      Toast.loading({ duration: 0, forbidClick: true });
      request(
        {
          url: "subInfo",
          method: "post",
          data: {
            uuid: localStorage.uuid,
            corporateName: this.corporate,
            name: this.inputName,
            tel: this.phone,
          }
        }).then((res) => {
          this.tipsConfig=res;
          if(this.tipsConfig.state==0){
            this.tipsConfig.msg="提交成功";
            if(localStorage.inputState){
              localStorage.inputState=JSON.stringify(Object.assign(JSON.parse(localStorage.inputState),{input:true}))
            }else{
              localStorage.inputState=JSON.stringify({input:true})
            }
          }
          this.showTips=true;
          Toast.clear();
        });

    },

    onTipsClose(json){
      if(json.state==0){
        this.$router.replace('SelectSignType')
      }
      this.showTips=false;
    },

  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';

.return {
  position: absolute;
  top: vw(307);
  right: vw(44);
  width: vw(140);
}

.Relative {
  position: absolute;
  top: vw(350);
  left: vw(45);
}

.frame {
  position: relative;
  width: vw(661);
}

.corporateName,
.name,
.telephone {
  width: vw(556);
}

.inputList {
  position: absolute;
  width: 90%;
  top: vw(9);
  left: vw(50);
  height: 100%;
  background: none;
  border: 0;
  font-size: vw(30);
  padding-right: vw(50);
  padding-left: vw(30);
}

.corporateName {
  position: absolute;
  top: vw(109);
  left: vw(50);
}

.fillIn,
.fillIn1,
.fillIn2 {
  width: vw(143);
}

.fillIn {
  position: absolute;
  top: vw(145);
  right: vw(85);
}

.name {
  position: absolute;
  top: vw(260);
  left: vw(50);
}

.fillIn1 {
  position: absolute;
  top: vw(298);
  right: vw(85);
}

.telephone {
  position: absolute;
  top: vw(412);
  left: vw(50);
}

.fillIn2 {
  position: absolute;
  top: vw(452);
  right: vw(85);
}

.green {
  position: absolute;
  top: vw(598);
  left: vw(50);
  width: vw(560);
}

.PunchTheClock {
  position: absolute;
  top: vw(613);
  left: vw(270);
  font-size: vw(31);
  letter-spacing: vw(3);
  color: #fff;
}

.text {
  position: absolute;
  top: vw(700);
  left: vw(218);
  width: vw(225);
}
</style>
