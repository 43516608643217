<template>
    <div id="app">
        <router-view />
        <!-- <button style="position: absolute;bottom: 0;right: 0;" @click="clearStor">清除缓存</button> -->
    </div>
</template>
<script>
import request from "@/util/request";
import { Toast } from 'vant';

export default {
    data() {
        return {

        };
    },
    methods: {
        clearStor(){
            localStorage.clear();
            location.href="/";
        }
    },
    mounted() {

        Toast.loading({ duration: 0, forbidClick: true });

        request({url:"getBatchInfo"}).then((res) => {
            //缓存里查不到批次号就创建用户 或者 缓存里查不到UUID 就创建用户 或者 缓存里的批次号不等于获取的批次号就创建用户
            Toast.clear();
            if(!localStorage.BatchInfo||localStorage.BatchInfo=="undefined"||!localStorage.uuid||res.data.batch!=JSON.parse(localStorage.BatchInfo).batch){
                Toast.loading({ duration: 0, forbidClick: true });
                localStorage.BatchInfo=JSON.stringify(res.data); 
                if(localStorage.inputState){
                    delete(localStorage.inputState)
                }
                request({url:"getUUID",params:{batch:res.data.batch}}).then((dd) => {
                    localStorage.uuid=dd.uuid;
                    Toast.clear();
                });
            }

        });
    }

};
</script>
<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

input {
    outline: none;
}

html,
body,
#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Main {
    width: 100%;
    height: 100%;

}

.Bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.Content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}</style>
