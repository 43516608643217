import { render, staticRenderFns } from "./uploadSuccess.vue?vue&type=template&id=35be6352&scoped=true&"
import script from "./uploadSuccess.vue?vue&type=script&lang=js&"
export * from "./uploadSuccess.vue?vue&type=script&lang=js&"
import style0 from "./uploadSuccess.vue?vue&type=style&index=0&id=35be6352&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35be6352",
  null
  
)

export default component.exports