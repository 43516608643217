<template>
  <div class="Wrap">
    <div class="Main">
      <template >
        <!-- 上传名片正反面 -->
        <div class="Bg"><img src="../assets/pege3/beijing3.jpg" alt=""></div>

        <div class="share bottomTopAnimate" @click="$router.replace('SelectSignType')"><img src="../assets/pege3/fenxiang3.png" alt=""></div>
        <!-- 上传名片正面 -->
        <div class="upload bottomTopAnimate" style="animation-delay: 200ms;">
          <img src="../assets/pege3/xiangjikuang3.png" alt="">
          <div class="coverImg"><img :src="zSrc" alt=""></div>
          <van-uploader style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;"
            :after-read="onRead1" accept="image/jpeg,image/png" max-count="1" />
        </div>

        <!-- 已上传名片正面 -->
        <!-- <div class="frame"><img src="../assets/pege3/kuang3.png" alt=""></div> -->

        <div class="front bottomTopAnimate" style="animation-delay: 250ms;"><img src="../assets/pege3/zhengmian.png" alt=""></div>
        <div class="camera bottomTopAnimate" style="animation-delay: 300ms;">
          <img src="../assets/pege3/xiangjikuang3.png" alt="">
          <div class="coverImg"><img :src="fSrc" alt=""></div>
          <van-uploader style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 0;"
            :after-read="onRead2" accept="image/jpeg,image/png" max-count="1" />
        </div>
        <div class="reverseside bottomTopAnimate" style="animation-delay:350ms;"><img src="../assets/pege3/fanmian.png" alt=""></div>
        <div class="PunchTheClock bottomTopAnimate" style="animation-delay: 400ms;" @click="submitPhoto"><img src="../assets/pege3/lvtiao3.png" alt=""></div>
        <div class="text bottomTopAnimate" style="animation-delay: 400ms;" @click="submitPhoto">点击签到</div>
        <div class="characters bottomTopAnimate" style="animation-delay: 450ms;"><img src="../assets/pege3/wenzi.png" alt=""></div>

      </template>
      <template v-if="isEdit">
        <div style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;">
          <Edit @cutChange="cutChange" :editSrc="editSrc" />
        </div>
      </template>
      <tips :cfg="tipsConfig" v-if="showTips" @close="onTipsClose"></tips>

    </div>
  </div>
</template>
<script>
// @ is an alias to /src 
import { Uploader } from 'vant';
import Edit from './edit.vue'
import { Toast, Dialog } from 'vant';
import request from "@/util/request";
import tips from '../components/tips.vue';
export default {
  name: "uploadInfo",
  data() {
    return {
      isEdit: false,
      editSrc: "",
      zSrc: "",
      fSrc: "",
      fx: 1,
      inputIndex: '',
      showTips: false,
    };
  },
  components: { ["van-uploader"]: Uploader, Edit, tips },
  methods: {
    onRead1(file) {
      this.editSrc = file.content;
      this.isEdit = true;
      this.fx = 1;
    },
    onRead2(file) {
      console.log(file)
      this.editSrc = file.content;
      this.isEdit = true;
      this.fx = 2;
    },
    //编辑完图片返回
    cutChange({ base64, blob }) { 
      let _formData = new FormData();
      let name =new Date().getTime() + Math.random().toString(36).slice(2);
      let file = new File([blob], name+".jpg", { type:  "text/plain;charset=utf-8"})
      _formData.append("file", file);
      Toast.loading({ duration: 0, forbidClick: true });
      request(
        {
          url: "submitImgPath",
          method: "post",
          data:_formData
        }).then((res) => {
          if (res.state == 0) {
            if (this.fx == 1) {
              this.zSrc = res.path;
            } else {
              this.fSrc = res.path;
            }
            this.isEdit = false
          } else {
            this.tipsConfig = res;
            this.showTips = true;
          }
          Toast.clear();
        });


    },
    submitPhoto() {
      if (!localStorage.uuid) {
        this.tipsConfig = { state: 1, msg: "用户信息有误" };
        this.showTips = true;
        return
      }
      if (localStorage.inputState && JSON.parse(localStorage.inputState).upload) {
        this.tipsConfig = { state: 1, msg: "已提交过名片" };
        this.showTips = true;
        return
      }
      if (!this.zSrc) {
        this.tipsConfig = { state: 1, msg: "请上传名片正面" };
        this.showTips = true;
        return
      }
      if (!this.fSrc) {
        this.tipsConfig = { state: 1, msg: "请上传名片反面" };
        this.showTips = true;
        return
      }
      Toast.loading({ duration: 0, forbidClick: true });
      request(
        {
          url: "submitBusinessCard",
          method: "post",
          data: {
            uuid: localStorage.uuid,
            frontBusinessCardPath: this.zSrc,
            backBusinessCardPath: this.fSrc,
          }
        }).then((res) => {
          if (res.state == 0) {
            if (localStorage.inputState) {
              localStorage.inputState = JSON.stringify(Object.assign(JSON.parse(localStorage.inputState), { upload: true }))
            } else {
              localStorage.inputState = JSON.stringify({ upload: true })
            }
            this.$router.replace({name:"uploadSuccess",query:{z:this.zSrc,f:this.fSrc}})
          } else {
            this.tipsConfig = res;
            this.showTips = true;
          }
          Toast.clear();
        });
    },
    onTipsClose(json) {
      this.showTips = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';

.Main :deep(.van-uploader__wrapper) {
  width: 100%;
  height: 100%;

  .van-uploader__upload {
    width: 100%;
    height: 100%;
  }
}

.share {
  position: absolute;
  top: vw(83);
  right: vw(110);
  width: vw(140);
}

.upload,
.camera {
  position: relative;
  width: vw(532);
}

.coverImg {
  position: absolute;
  width: 101%;
  top: vw(-1);
  left: vw(-1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: vw(16);

  img {
    width: 100%;
  }
}



.upload,
.frame {
  position: absolute;
  top: vw(137);
  left: vw(109);
  width: vw(532);
}

.front,
.reverseside {
  width: vw(169);
}

.front {
  position: absolute;
  top: vw(481);
  left: vw(111);
}

.camera {
  position: absolute;
  top: vw(575);
  left: vw(109);
}

.reverseside {
  position: absolute;
  top: vw(920);
  left: vw(109);
}

.PunchTheClock {
  position: absolute;
  top: vw(1062);
  left: vw(95);
  width: vw(560);
}

.text {
  position: absolute;
  top: vw(1078);
  left: vw(312);
  color: #fff;
  font-size: vw(31);
  letter-spacing: vw(3);
}

.characters {
  position: absolute;
  top: vw(1160);
  left: vw(270);
  width: vw(208);
}
</style>
