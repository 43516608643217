<template>
  <div class="Wrap">
    <div class="Main">
      <!-- 签到方法文字输入基本信息和拍照上传 -->
      <div class="Bg"><img src="../assets/SelectSignType/Bg.jpg" alt=""></div>
      <div class="Content">

        <div class="LeftLogo bottomTopAnimate"><img src="../assets/pege6/zuologo6.png" alt=""></div>
        <div class="RightLogo bottomTopAnimate"><img src="../assets/pege6/youlogo66.png" alt=""></div>
        <div class="share bottomTopAnimate" style="animation-delay: 200ms;" @click="$router.replace('/')"><img src="../assets/pege6/fenxiang6.png" alt=""></div>

        <div class="frame bottomTopAnimate" style="animation-delay: 300ms;">
          <div class="Relative">
            <img src="../assets/pege6/kuang6.png" alt="">
            <div class="PunchTheClock"><img src="../assets/pege6/qiandao1.png" alt=""></div>
            <div class="green" @click="toPage('inputInfo')"><img src="../assets/pege6/lvtiao6.png" alt="">
            </div>
            <div class="text" @click="toPage('inputInfo')" >文字输入基本信息</div>
            <div class="PunchTheClock1"><img src="../assets/pege6/qiandao2.png" alt=""></div>
            <div class="greenColor" @click="toPage('uploadInfo')"><img src="../assets/pege6/lvtiao6.png">
            </div>
            <div class="text1"  @click="toPage('uploadInfo')">拍照上传名片</div>
          </div>
        </div>

      </div>

      <tips :cfg="tipsConfig" v-if="showTips" @close="onTipsClose"></tips>

    </div>
  </div>
</template>
<script>
import tips from '../components/tips.vue';

export default {
  name: "SelectSignType",
  data() {
    return {
        showTips:false,
        tipsConfig:{},
    };
  },
  components:{tips},
  methods: {

    toPage(name){

        if(localStorage.inputState){
             if(name == 'inputInfo'&&JSON.parse(localStorage.inputState).input){
                this.tipsConfig.state="1";
                this.tipsConfig.msg="已提交过文字基本信息";
                this.showTips=true;
                return
             }
             if(name == 'uploadInfo'&&JSON.parse(localStorage.inputState).upload){
                this.tipsConfig.state="1";
                this.tipsConfig.msg="已提交过名片";
                this.showTips=true;
                return
             }
        }

        this.$router.replace(name)

    },
    onTipsClose(json){ 
      this.showTips=false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';



.share {
  position: absolute;
  top: vw(369);
  right: vw(60);
  width: vw(119);
}

.frame {
  position: absolute;
  top: vw(408);
  left: vw(45);
  width: vw(661);
}

.Relative {
  position: relative;
}

.PunchTheClock,
.PunchTheClock1 {
  width: vw(111);
}

.PunchTheClock {
  position: absolute;
  top: vw(115);
  left: vw(50);
}

.green,
.greenColor {
  width: vw(560);
}

.green {
  position: absolute;
  top: vw(165);
  left: vw(50);
}

.text,
.text1 {
  font-size: vw(31);
  color: #fff;
}

.text {
  position: absolute;
  top: vw(180);
  left: vw(210);
}

.PunchTheClock1 {
  position: absolute;
  top: vw(320);
  left: vw(50);
}

.greenColor {
  position: absolute;
  top: vw(373);
  left: vw(50);
}

.text1 {
  position: absolute;
  top: vw(390);
  left: vw(240);
}
</style>
