import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue' 
import uploadInfo from '../views/uploadInfo.vue' 
import uploadSuccess from '../views/uploadSuccess.vue'
import study from '../views/study.vue' 


import SelectSignType from '../views/SelectSignType.vue'
import inputInfo from '../views/inputInfo.vue'
import editImg from '../views/edit.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },  
  {
    path: '/uploadInfo',
    name: 'uploadInfo',
    component: uploadInfo
  }, 
  
  {
    path: '/uploadSuccess',
    name: 'uploadSuccess',
    component: uploadSuccess
  },   
  {
    path: '/study',
    name: 'study',
    component: study
  },  
  {
    path: '/SelectSignType',
    name: 'SelectSignType',
    component: SelectSignType
  }, 
  {
    path: '/inputInfo',
    name: 'inputInfo',
    component: inputInfo
  }, 
  {
    path: '/editImg',
    name: 'editImg',
    component: editImg
  },
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
