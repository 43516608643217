<template>
  <div class="Wrap">
    <div class="Main">
      <!-- 名片上传签到成功 -->
      <div class="Bg"><img src="../assets/pege3/beijing3.jpg" alt=""></div>
      
      <div>
        <div class="PunchTheClock bottomTopAnimate"><img src="../assets/pege5/qiandaochenggong.png" alt=""></div>
        <div class="frame bottomTopAnimate" style="animation-delay: 250ms;"><img :src="$route.query.z" alt=""></div>
        <div class="TextTable bottomTopAnimate" style="animation-delay: 350ms;"><img :src="$route.query.f" alt=""></div>
        <div class="share bottomTopAnimate" style="animation-delay: 450ms;" @click="$router.replace('SelectSignType')"><img src="../assets/pege5/fenxiang5.png" alt=""></div>
      </div>

    </div>
  </div>
</template>
<script>
// @ is an alias to /src 
export default {
  name: "uploadSuccess", 
  data() {
    return {
       
    };
  },
  mounted(){
    
  },
  methods: {
     
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';
  
.background {
  width: vw(750);
}
.PunchTheClock {
  position: absolute;
  top: vw(108);
  left: vw(320);
  width: vw(114);
}
.frame {
  position: absolute;
  top: vw(390);
  left: vw(110);
  width: vw(532);
  img{
    border-radius: vw(16);
  }
}
.TextTable {
  position: absolute;
  top: vw(755);
  left: vw(110);
  width: vw(532);
  img{
    border-radius: vw(16);
  }
}
.share {
  width: vw(139);
  position: absolute;
  top: vw(1144);
  left: vw(305);
}

</style>
