<template>
  <div class="Wrap">
    <div class="Main">
      <!-- 满意度调查表 -->

      <div style="width: 100%;height: 100%;overflow-y: auto;">
        <div class="questionnaire"><img src="../assets/biao/dingbu.png" alt=""></div>

        <div>
          <template v-for="(item, index) in QAData.list">
            <div>
              <div class="one">{{ (index + 1) }}.{{ item.title }}<span>*</span></div>
              <div class="frame">
                <!-- 1开始 -->
                <template v-for="(list, idx) in item.answers">
                  <div class="A1" :class="{ Select: list.select }" @click="SelectQA(index, list)">
                    <div class="round"><span></span></div>
                    <div>{{ list.answer }}</div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <div class="propose" v-if="QAData.list && QAData.list.length > 0">
            <div class="one">{{ (QAData.list.length + 1) }}.您还有哪些意见和建议需要补充说明<span>*</span></div>
            <div class="frame" style="border: 0;">
              <!-- 1开始 -->
              <div class="A1">
                <img src="../assets/biao/tianxie.png" alt="">
                <input v-model="inputPropose" @focus="isFocus = true" @blur="isFocus = false" class="textare" type="text">
                <div class="textareTips" v-if="!inputPropose && !isFocus">
                  <img src="../assets/biao/inputTipsText.jpg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="submitBtnWrap">
          <div class="green" @click="submitInfo" v-if="!isSubmitG">
            <div class="upload">提交</div>
          </div>
          <div class="green" @click="$router.replace('/')"  style="margin-left: 10px;">
            <div class="upload">返回</div>
          </div>
        </div>


        <div class="box"></div>

      </div>

      <tips :cfg="tipsConfig" v-if="showTips" @close="onTipsClose"></tips>


    </div>
  </div>
</template>
<script>
// @ is an alias to /src 
import request from "@/util/request";
import { Toast, Dialog } from 'vant';
import tips from '../components/tips.vue';
export default {
  name: "study",
  data() {
    return {
      QAData: {},
      tipsConfig: {},
      showTips: false,
      inputPropose: "",
      isFocus: false,
      isSubmitG: false
    };
  },
  components: { tips },

  mounted() {
    Toast.loading({ duration: 0, forbidClick: true });
    if (localStorage.BatchInfo) {
      request({ url: "getAnswer", params: { batch: JSON.parse(localStorage.BatchInfo).batch } }).then((res) => {
        if (res.state == 0) {
          this.QAData = res.data;
        } else {
          this.tipsConfig = res;
          this.showTips = true;
        }
        Toast.clear();
      });
    }

    if(localStorage.inputState&&JSON.parse(localStorage.inputState).study){
      this.isSubmitG=true;
    }

  },
  methods: {

    SelectQA(pretIndex, list) {
      this.QAData.list[pretIndex].answers.forEach((item, idx) => {
        this.$set(item, "select", false)
      })
      this.$set(list, "select", true)
    },

    submitInfo() {


      if (!localStorage.uuid) {
        this.tipsConfig = {
          state: 1,
          msg: "用户信息有误"
        };
        this.showTips = true;
        return;
      }
      if (localStorage.inputState && JSON.parse(localStorage.inputState).study) {
        this.tipsConfig = {
          state: 1,
          msg: "您已提交过调查表"
        };
        this.showTips = true;
        return;
      }

      let submitArr = [];

      this.QAData.list.forEach((item, index) => {

        item.answers.forEach((list, idx) => {

          if (list.select) {
            submitArr.push({ tid: this.QAData.list[index].id, aid: list.id })
          }

        })

      })

      let alertText = "";
      this.QAData.list.forEach((item, index) => {
        if (!submitArr.find(list => list.tid == item.id)) {
          alertText = item.title
        }
      })

      if (alertText) {
        this.tipsConfig = {
          state: 1,
          msg: "请选择：" + alertText
        };
        this.showTips = true;
        return
      }


      if (!this.inputPropose) {
        this.tipsConfig = {
          state: 1,
          msg: "请填写意见和建议"
        };
        this.showTips = true;
        return
      }

      request({ url: "submitAnswer", method: "post", data: { uuid: localStorage.uuid, propose: this.inputPropose, answers: submitArr } }).then((res) => {
        if (res.state == 0) {
          this.tipsConfig = res;
          if (localStorage.inputState) {
            localStorage.inputState = JSON.stringify(Object.assign(JSON.parse(localStorage.inputState), { study: true }))
          } else {
            localStorage.inputState = JSON.stringify({ study: true })
          }
          this.tipsConfig.msg = "提交成功"
          this.showTips = true;
          this.isSubmitG=true;
        } else {
          this.tipsConfig = res;
          this.showTips = true;

        }
        Toast.clear();
      });
    },

    onTipsClose(json) {
      this.showTips = false;
    },

  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';


.questionnaire {
  width: vw(750);
  margin-bottom: vw(70);
}

.one {
  width: vw(558);
  margin-left: vw(98);
  margin-bottom: vw(25);
  font-size: vw(30);
  display: flex;
  align-items: center;

  span {
    color: red;
  }
}

.textare {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: vw(30);
  background: none;
  border: none;
  padding: 0 vw(25)
}

.textareTips {
  width: vw(140);
  position: absolute;
  top: vw(8);
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
}

.frame {
  width: vw(558);
  border: 1px solid #bec1c1;
  border-radius: vw(20);
  margin-left: vw(98);
  margin-bottom: vw(50);

}

.A1 {
  display: flex;
  align-items: center;
  width: vw(558);
  height: vw(65);
  border-bottom: 1px solid #bec1c1;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  &.Select {
    .round {
      span {
        display: block;
      }
    }
  }
}


.round {
  margin-left: vw(27);
  margin-right: vw(25);
  width: vw(23);
  height: vw(23);
  position: relative;
  border: vw(1) solid #a6e0d5;
  border-radius: 50%;

  span {
    position: absolute;
    width: 70%;
    height: 70%;
    background: #33b89f;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
}

.submitBtnWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: vw(558);
  margin: auto;
  margin-top: vw(110);
}
.green {
  padding-top: vw(10);
  padding-bottom: vw(10);
  border-radius: vw(279);
  width:100%;
  height: vw(65);
  background-color: #00a687;
}

.upload {
  text-align: center;
  font-size: vw(35);
  color: #fff;
}

.box {
  width: vw(750);
  height: vw(100);
}
</style>
