import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import WechatSDKModel  from './util/wxShare'
import vconsole from 'vconsole'
if (location.href.indexOf("debug") > -1) {
  new vconsole;
}
Vue.config.productionTip = false





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


let protocol=document.location.protocol+"//";


WechatSDKModel.setWXApiConfig(
  protocol+"wechat.makebuluo.cn/wechat/share/JsShare.php",
  (e) => {
    let shareTitle = "会议签到";
    let shareContent = "会议签到";

    WechatSDKModel.shareToFriend(
      shareTitle,
      shareContent,
      protocol+"testex.makebuluo.cn/",
      protocol+"testex.makebuluo.cn/shareImg.jpg",
      (es) => {
        console.log("分享成功");
      }
    );

    WechatSDKModel.shareToTimeline(
      shareTitle,
      shareTitle,
      protocol+"testex.makebuluo.cn/",
      protocol+"testex.makebuluo.cn/shareImg.jpg",
      (es) => {
        console.log("分享成功");
      }
    ); 
  }
);