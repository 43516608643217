<template>
  <div class="Wrap">
    <div class="Main">
      <!-- 会议签到和满意度调查表跳转 -->

      <div class="backgroundbox">
          <div class="background"><img src="../assets/home/beijing1.jpg" alt=""></div>
          <div class="logo bottomTopAnimate"><img src="../assets/home/logo1.png" alt=""></div>
          <div class="frame bottomTopAnimate" style="animation-delay: 300ms;"><img src="../assets/home/diaochabiao.png" @click="$router.replace('SelectSignType')" alt=""></div>
          <div class="questionnaire bottomTopAnimate" style="animation-delay: 400ms;"><img src="../assets/home/diaochabiao2.png" @click="$router.replace('study')" alt=""></div> 
      </div>
      

    </div>
  </div>
</template>
<script>
import router from '@/router';

// @ is an alias to /src 
export default {
  name: "HomeView", 
  data() {
    return {
       
    };
  },
  methods: {
     
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';
 
.background {
  width:vw(750);
}
.logo {
  position: absolute;
  top:vw(60);
  left:vw(290);
  width: vw(167);
}
.frame,.questionnaire {
  width: vw(565);
}
.frame {
  position: absolute;
  top: vw(972);
  left: vw(95);
}
.questionnaire {
  position: absolute;
  top: vw(1116);
  left: vw(95);
}
 
</style>
