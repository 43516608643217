<template>
  <div class="Wrap">
    <div class="Main">
      <vue-picture-cut :rotate-control="true" :cancel="true" cancel-name="取消" confirm-name="保存" :mskOption="mskOption"
        :src="editSrc" @on-change="cutChange">
        <!-- <vue-picture-cut-menu menuRotateName="旋转值" confirm-name="保存" cancel-name="取消" slot="menu" /> -->

      </vue-picture-cut>
    </div>
  </div>
</template>
<script>
import { VuePictureCut, VuePictureCutMenu } from 'vue-picture-cut';
// @ is an alias to /src 
export default {
  name: "editImg",
  props:['editSrc'],
  components: {
    VuePictureCut,
    VuePictureCutMenu,
  },
  data() {
    return {
      src: null,
      blob: null,
      base64: null,
      mskOption: {
        width: 266, height: 163, isRound: false, resize: false
      }
    };
  },
  methods: { 
    /**
     * @param blob      BLOB对象
     * @param base64    base64字符串
     */
    cutChange({ blob, base64 }) {
      this.blob = blob;
      this.base64 = base64;
      this.$emit("cutChange", { base64, blob })
    }
  },
  created() {
    this.src = require("@/assets/biaotanchuang/buketijiao.png");
  }
};
</script>
<style lang="scss" scoped>
@import '../assets/css/public.scss';
@import "~vue-picture-cut/lib/vue-picture-cut.css";

.Main :deep(.vue-picture-cut_button) {
  font-size: 0;

  &::after {
    content: "确认";
    font-size: vw(30);
  }
}</style>
